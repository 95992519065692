<template>
  <div class="donate">
    <div class="flex-column align-items-center">
      <h5 class="donation-header">DONATION</h5>
      <h6 class="donation-header">{{ msg }}</h6>
      <form id="payment-form" class="flex-column align-items-center" >
        <div>
          <div class="row">
            <div class="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-3">
              <div class="col-12 m-0 p-0">
                <label for="donate5" class="donation-label">
<!--                  <span class="fw-bold">Donate</span> --> <br/>
                  <font-awesome-icon icon="hand-holding-heart"
                                     class="icon-fa donation-icon"
                                     style="color: red;"
                  />
                  <br/><br/>
                  <div class="donation-tickbox-container">
                    {{ donatePrice5 }} <br/>
                    <input v-model="donationPlan" type="radio" id="donate5" name="donate5" :value="donatePrice5Value">
                  </div>
                </label>
              </div>
            </div>
            <div class="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-3">
              <div class="col-12 m-0 p-0">
                <label for="donate10" class="donation-label">
<!--                  <span class="fw-bold">Donate</span> --> <br/>
                  <font-awesome-icon icon="heart"
                                     class="icon-fa donation-icon"
                                     style="color: red;"
                  />
                  <br/><br/>
                  <div class="donation-tickbox-container">
                    {{ donatePrice10 }} <br/>
                    <input v-model="donationPlan" type="radio" id="donate10" name="donate10" :value="donatePrice10Value">
                  </div>
                </label>
              </div>
            </div>
            <div class="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-3">
              <div class="col-12 m-0 p-0">
                <label for="donate20" class="donation-label">
<!--                  <span class="fw-bold">Donate</span> --> <br/>
                  <font-awesome-icon icon="grin-hearts"
                                     class="icon-fa donation-icon"
                                     style="color: red; border-radius: 50%; background-color: white;"
                  />
                  <br/><br/>
                  <div class="donation-tickbox-container">
                    {{ donatePrice20 }} <br/>
                    <input v-model="donationPlan" type="radio" id="donate20" name="donate20" :value="donatePrice20Value">
                  </div>
                </label>
              </div>
            </div>
            <div class="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-3">
              <div class="col-12 m-0 p-0">
                <label for="donateOther" class="donation-label">
                  <span class="fw-bold">Other</span> <br/>
                  <font-awesome-icon icon="gift"
                                     class="icon-fa donation-icon"
                                     style="color: red; border-radius: 50%; background-color: white;"
                  />
                  <br/><br/>
                  <div class="donation-tickbox-container">
                    <span class="price-color">£</span>
                    <input v-model="donatePriceOther"
                           class="donation-input"
                           placeholder="..."
                           min="0"
                           type="number"
                           step="1"
                           pattern="[0-9]"
                           @keydown="filterKey"
                           @input="filterInput"
                           @click="checkOtherCheckbox"
                    >
                    <br/>
                    <input v-model="donationPlan" type="radio" id="donateOther" name="donateOther" :value="donatePriceOtherValue">
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div v-show="donationPlan" class="row m-0 mt-2 mb-2 p-0 d-flex justify-content-center">
            <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 px-5">

              <div v-if="loadingPaymentMethods">
                <!-- Loading -->
                <Loader class="mt-2" :loading="loadingPaymentMethods" :small="false"></Loader>
              </div>

              <p class="text-start ps-2 font-13" v-show="!loadingPaymentMethods">Payment Methods</p>

              <div v-show="!loadingPaymentMethods && paymentMethodsLoadStatus === 2 && paymentMethods.length === 0"
                   class="payment-method border rounded row p-0 pt-2 pb-2 m-0 mt-2 mb-2 cursor-pointer">
                No payment methods added, payment methods are added automatically when you do your first payment.
              </div>

              <div v-show="!loadingPaymentMethods && paymentMethodsLoadStatus === 2 && paymentMethods.length > 0">
                <div v-for="(method, key) in paymentMethods"
                     v-bind:key="'method-'+key"
                     v-on:click="toggleSelectedMethod(method)"
                     class="payment-method border rounded row p-0 pt-2 pb-2 m-0 mt-2 mb-2 cursor-pointer"
                     v-bind:class="{ 'bg-selected-payment-method text-light': paymentMethodSelected && paymentMethodSelected.id === method.id }"
                >
                  <div class="col-2 text-start">
                    <font-awesome-icon icon="credit-card" class="icon-fa" />
                    {{ method.brand.charAt(0).toUpperCase() }}{{ method.brand.slice(1) }}
                  </div>
                  <div class="col-4 text-start">
                    Card: {{ method.last_four }}
                  </div>
                  <div class="col-4 text-start">
                    Exp: {{ method.exp_month }}/{{ method.exp_year }}
                  </div>
                  <div class="col-2 text-center">
                    <span data-tooltip="tooltip">
                      <div class="no-text-wrap overflow-hidden text-truncate">
                        <button type="button" :disabled="processing" @click.stop="removePaymentMethod(method.id)" class="delete-payment-method-btn d-inline">
                          <font-awesome-icon icon="times-circle" class="icon-fa required delete-icon" />
                        </button>
                        <span class="tooltiptext">Delete payment method</span>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <br/>
              <p class="text-start ps-2 font-13" v-show="!loadingPaymentMethods">Card Payment</p>

              <input v-show="!loadingPaymentMethods" id="card-holder-name" placeholder="Card holder name..." type="text" v-model="cardHolderName" class="form-control mb-2 font-13">

              <div v-show="!loadingPaymentMethods" id="payment-card" class="form-control">
                <!-- A Stripe Element will be inserted here. -->
              </div>

<!--              <div id="payment-element">-->
<!--                &lt;!&ndash;Stripe.js injects the Payment Element&ndash;&gt;-->
<!--              </div>-->

<!--              <div id="payment-message" class="hidden"></div>-->


              <div id="error-message">
                <!-- Display error message to your customers here -->
              </div>
            </div>
          </div>
          <div v-show="!loadingPaymentMethods && donationPlan" class="row m-0 mt-2 mb-2 p-0 d-flex justify-content-center">
            <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-6 px-5">
              <button v-show="!loadingPaymentMethods" type="submit" :disabled="processing" @click.prevent="donate()" class="btn donate-btn btn-block">
                {{ processing ? "Please wait" : "Donate" }}
              </button>
              <div v-if="validationErrors && validationErrors.length > 0" class="global-error mt-2">
                <ValidationErrors :errors="validationErrors"/>
              </div>
            </div>
          </div>
        </div>

        <div v-show="showThankYouMessage" class="thank-you-msg-container pt-2">
          <p class="fw-bold">{{ this.thankYouMessage }}</p>
          <div class="row">
            <div class="col">
              <div id="smallHeart3"></div>
            </div>
            <div class="col">
              <div id="smallHeart1"></div>
            </div>
            <div class="col">
              <div id="heart"></div>
            </div>
            <div class="col">
              <div id="smallHeart2"></div>
            </div>
            <div class="col">
              <div id="smallHeart4"></div>
            </div>
          </div>
        </div>

        <div style="margin-top: 150px;" id="thankYou"></div>

      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import ValidationErrors from './ValidationErrors';
import Loader from './Loader';

export default {
  name: 'Donation',
  components: {
    'ValidationErrors': ValidationErrors,
    'Loader': Loader
  },
  data () {
    return {
      stripeApiToken: '',
      stripe: '',
      elements: '',
      card: '',
      intentToken: '',
      cardHolderName: '',
      paymentStatus: 0,
      paymentError: '',
      paymentMethods: [],
      paymentMethodsLoadStatus: 0,
      paymentMethodSelected: {},
      loadingPaymentMethods: true,
      msg: 'How much would you like to donate?',
      donatePrice5:"£5.00",
      donatePrice10:"£10.00",
      donatePrice20:"£20.00",
      donatePriceOther: "",
      donatePrice5Value: process.env.VUE_APP_STRIPE_DONATE_PRICE_FIVE,
      donatePrice10Value: process.env.VUE_APP_STRIPE_DONATE_PRICE_TEN,
      donatePrice20Value: process.env.VUE_APP_STRIPE_DONATE_PRICE_TWENTY,
      donatePriceOtherValue: process.env.VUE_APP_STRIPE_DONATE_PRICE_OTHER,
      donationPlan: '',
      processingIntent: false,
      processing: false,
      showThankYouMessage: false,
      thankYouMessage: '',
      validationErrors: []
    }
  },
  mounted: async function () {
    // Get stripe API key
    await this.getStripeApiKey();

    this.$root.$on('setDonationPlan', (plan) => this.setDonationPlan(plan));
    this.$root.$on('donate', () => this.donate());

    // Load User Intent for Stripe Payment System
    this.loadUserIntent();
    // Load User Payment Methods from Stripe (if exists)
    this.loadPaymentMethods();

    this.stripe = Stripe( this.stripeApiToken );

    this.elements = this.stripe.elements();
    this.card = this.elements.create('card', {
      hidePostalCode: true
    });

    // Thats wrong, I need to find way
    // let paymentElement = elements.create('payment');
    // paymentElement.mount('#payment-element');

    this.card.mount('#payment-card');
  },
  beforeDestroy() {
    // Must destroy global trigger before we leave the page/component, otherwise it will layer on each other
    // and when we call it, it will trigger several times, breaking functionality
    this.$root.$off('setDonationPlan');
    this.$root.$off('donate');
  },
  methods: {
    async getStripeApiKey() {
      this.processing = true;
      await axios.get('api/stripe/get-api-key').then(({data})=>{
        this.stripeApiToken = data;
        this.processing = false
      }).catch(({response})=>{
        alert(response.data.message)
        this.processing = false
      }).finally(()=> {
        this.processing = false
      })
    },
    loadUserIntent() {
      this.loadIntent();
    },
    filterKey(e) {
      const key = e.key;

      // If is '.' key, stop it
      if (key === '.')
        return e.preventDefault();

      // OPTIONAL
      // If is 'e' key, stop it
      if (key === 'e')
        return e.preventDefault();
    },
    // This can also prevent copy + paste invalid character
    filterInput(e) {
      e.target.value = e.target.value.replace(/[^0-9]+/g, '');
    },
    checkOtherCheckbox() {
      this.donationPlan = this.donatePriceOtherValue;
    },
    async loadIntent() {
      this.processingIntent = true;
      await axios.get('api/user/setup-intent').then(({data})=>{
        this.intentToken = data;
        this.processingIntent = false
      }).catch(({response})=>{
        alert(response.data.message)
        this.processingIntent = false
      }).finally(()=> {
        this.processingIntent = false
      })
    },
    async loadPaymentMethods() {
      this.loadingPaymentMethods = true;
      this.paymentMethodsLoadStatus = 1;

      let formData = new FormData()
      formData.append('user_id', this.$store.state.auth.user.id);

      await axios.post('/api/user/payment-methods', formData)
          .then( function( response ){
            this.paymentMethods = response.data;

            this.paymentMethodsLoadStatus = 2;
          }.bind(this));
      this.loadingPaymentMethods = false;
    },
    toggleSelectedMethod(method) {
      if (this.paymentMethodSelected && this.paymentMethodSelected.id == method.id) {
        this.paymentMethodSelected = {};
      } else {
        this.paymentMethodSelected = method;
      }
    },
    async donate() {
      this.processing = true;
      this.paymentStatus = 1;

      let savePaymentMethod = true;
      let paymentMethod = {};

      if (this.paymentMethodSelected && Object.keys(this.paymentMethodSelected).length > 0) {
        paymentMethod = this.paymentMethodSelected.id;
        savePaymentMethod = false;
      } else {
        paymentMethod = {
          card: this.card,
          billing_details: {
            name: this.cardHolderName
          }
        };
      }


      this.stripe.confirmCardSetup(
          this.intentToken.client_secret, {
            payment_method: paymentMethod
          }
      ).then(async function (result) {
        if (result.error) {
          this.paymentStatus = 3;
          this.validationErrors = [];
          // Assign error so it can be handled by ValidationErrors component
          this.validationErrors[0] = {0: result.error.message};
          // Reset selected payment method
          this.paymentMethodSelected = {};
          // console.log(result);
          this.processing = false;
        } else {
          this.validationErrors = [];
          this.paymentMethodSelected = result.setupIntent.payment_method;
          // console.log(result);
          // console.log('start NOT paymentMethodSelected');
          // console.log(result.setupIntent.payment_method);
          // console.log(this.paymentMethodSelected);
          // console.log('end NOT paymentMethodSelected');
          if (savePaymentMethod) {
            this.savePaymentMethod(result.setupIntent.payment_method);
          }
          let payment = await this.oneOffChargeMethod(result.setupIntent.payment_method);

          // Reset selected payment method
          this.paymentMethodSelected = {};

          if (payment === true) {
            // Here I will add updateSubscription(method) to update subscription and pass payment_method to it
            // this.updateSubscription();
            this.paymentStatus = 2;
            this.card.clear();
            this.cardHolderName = '';
            // console.log(result);

            this.thankYouMessage = "Thanks! We really do appreciate your support!"
            this.showThankYouMessage = true;
            let thankYou = document.getElementById('thankYou');
            if (thankYou) {
              thankYou.scrollIntoView({behavior: 'smooth', block: 'center'});
            }

            this.processing = false;

            const timeout = setTimeout(() => {
              this.showThankYouMessage = false;
              this.thankYouMessage = null;
              clearTimeout(timeout);
            }, 10000);
          }
        }
      }.bind(this)).finally(() => {
        // Load user intent again
        this.loadUserIntent();
        // Reset selected payment method
        this.paymentMethodSelected = {};
        this.processing = false;
      });
    },
    async oneOffChargeMethod(method) {
      return await axios.post('/api/user/one-off-charge', {
        payment_method: method,
        plan: this.donationPlan,
        price: this.donatePriceOther
      }).then(function(response) {
        this.validationErrors = null;
        return true;
        // console.log(response);
      }.bind(this)).catch(({response})=> {
        if (response && response.data && response.data.errors) {
          if (response.data.errors instanceof Array) {
            this.validationErrors = response.data.errors;
          } else {
            for (const [key, value] of Object.entries(response.data.errors)) {
              this.validationErrors.push(value);
            }
          }
        }
        return false;
      });
    },
    savePaymentMethod(method){
      let formData = new FormData()

      formData.append('user_id', this.$store.state.auth.user.id);
      formData.append('payment_method', method);

      axios.post('/api/user/payments', formData).then(function(){
        this.loadPaymentMethods();
      }.bind(this));
    },
    async removePaymentMethod(paymentID){
      let formData = new FormData()

      formData.append('user_id', this.$store.state.auth.user.id);
      formData.append('id', paymentID);
      // Prevent user to be able to delete payments methods if their subscription is active
      await axios.post('/api/user/remove-payment', formData).then(function(response){
        this.loadPaymentMethods();
      }.bind(this));
    },
  },
  created() {
    // console.log('created donate component');
  }
}
</script>

<style lang="scss">

.donate {
  /* start tooltip - popups info on icons */
  .tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    text-align: center;
    font-size: 10px;
    border-radius: 6px;
    padding: 5px 5px;

    /* Position the tooltip */
    z-index: 1000;
  }

  .loader {
    position: relative;
    display: block;
    min-height: 180px;
    top: 0;
    left: 0;
  }

  .payment-method {
    font-size: 13px;
    background-color: #f1f1f1;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    &:hover {
      background-color: white;
      color: black;
      transition: 0.5s;
      -moz-transition: 0.5s;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
    }
  }

  .bg-selected-payment-method {
    background-color: #bdfdbd;
    color: black !important;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    &:hover {
      background-color: #bdfdbd !important;
      color: black !important;
      transition: 0.5s;
      -moz-transition: 0.5s;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
    }
  }

  .delete-payment-method-btn {
    display: block;
    width: auto;
    height: auto;
    border-radius: 50%;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    .delete-icon {
      transition: 0.5s;
      -moz-transition: 0.5s;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      border-radius: 50%;
      color: red;
      background-color: white;
    }
    .delete-icon:hover {
      transition: 0.5s;
      -moz-transition: 0.5s;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      border-radius: 50%;
      color: white;
      background-color: red;
    }
  }
}

.donation-header {
  letter-spacing: 2px;
}
.donation-label {
  position: relative;
  height: 120px;
  width: 80%;
  min-width: 80%;
  border-radius: 10px;
  border: 1px solid #ced4da;
  box-shadow: 0rem 0.5rem 0.5rem rgba(0, 0, 0, .25);
  padding: 5px;
  margin: 5px 0px 15px 0px;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  .donation-icon {
    width: 30px;
    height: auto;
  }
  .donation-icon path {
    stroke: black;
    stroke-width: 20;
  }
  .donation-input {
    width: 100px;
    background-color: rgba(255, 255, 255, 0);
    text-align: center;
    border: none;
    border-radius: 10px;
    color: limegreen;
    font-weight:bold;
  }
  .donation-input:focus {
    background-color: rgba(255, 255, 255, 0);
    text-align: center;
    border: none;
    border-radius: 10px;
  }
  .donation-input:focus-visible {
    background-color: rgba(255, 255, 255, 0);
    text-align: center;
    border: none;
    outline: none;
    border-radius: 10px;
  }
  .donation-input:hover {
    background-color: rgba(255, 255, 255, 0);
    text-align: center;
    border: 1px solid #ced4da;
    border-radius: 10px;
  }
}
.donation-label:hover {
  cursor: pointer;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}
.donation-label:hover .donation-icon {
  transition: 1s;
  animation: wiggle 2s linear;
  -moz-animation: wiggle 2s linear;
  -o-animation: wiggle 2s linear;
  -webkit-animation: wiggle 2s linear;
}

.donate-btn {
  border-radius: 5px;
  border: 1px solid #ff8484 !important;
  min-width: 80px;
  color: red !important;
  transition: 0.5s;
  letter-spacing: 0.1em;
}
.donate-btn:hover {
  background-color: #ff8484;
  border-radius: 5px;
  color: white !important;
  transition: 0.5s;
}

/* Wiggle Animation */
@keyframes wiggle {
  0%, 7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%, 100% {
    transform: rotateZ(0);
  }
}

.donation-tickbox-container {
  position: absolute;
  left: 0;
  bottom: 0;
  text-align: center;
  width: 100%;
  color: limegreen;
  font-weight:bold;
}

/* ******** Heart Animations ******** */

/* Start Big Heart */
#heart {
  position: relative;
  width: 100px;
  height: 90px;
  animation: heartbeat 1s infinite;
  margin:0 auto;
  z-index: 9999;
}
#heart:before,
#heart:after {
  position: absolute;
  content: "";
  left: 50px;
  top: 0;
  width: 50px;
  height: 80px;
  background: red;
  -moz-border-radius: 50px 50px 0 0;
  border-radius: 50px 50px 0 0;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 100%;
  -moz-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  -o-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
#heart:after {
  left: 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  transform-origin :100% 100%;
}

@keyframes heartbeat
{
  0%
  {
    transform: scale( .75 );
  }
  20%
  {
    transform: scale( 1 );
  }
  40%
  {
    transform: scale( .75 );
  }
  60%
  {
    transform: scale( 1 );
  }
  80%
  {
    transform: scale( .75 );
  }
  100% {
    transform: scale( .75 );
  }
}
/* End Big Heart */

/* Start Small Heart 1 */
#smallHeart1 {
  position: relative;
  width: 50px;
  height: 40px;
  animation: heartbeat 1s infinite;
  margin:0 auto;
  z-index: 9999;
}
#smallHeart1:before,
#smallHeart1:after {
  position: absolute;
  content: "";
  left: 25px;
  top: 0;
  width: 25px;
  height: 40px;
  background: red;
  -moz-border-radius: 25px 25px 0 0;
  border-radius: 25px 25px 0 0;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 100%;
  -moz-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  -o-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
#smallHeart1:after {
  left: 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  transform-origin :100% 100%;
}
/* End Small Heart 1 */

/* Start Small Heart 2 */
#smallHeart2 {
  position: relative;
  width: 50px;
  height: 40px;
  animation: heartbeat 1s infinite;
  margin:0 auto;
  z-index: 9999;
}
#smallHeart2:before,
#smallHeart2:after {
  position: absolute;
  content: "";
  left: 25px;
  top: 0;
  width: 25px;
  height: 40px;
  background: red;
  -moz-border-radius: 25px 25px 0 0;
  border-radius: 25px 25px 0 0;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 100%;
  -moz-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  -o-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
#smallHeart2:after {
  left: 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  transform-origin :100% 100%;
}
/* End Small Heart 2 */

/* Start Smallest Heart 3 */
#smallHeart3 {
  position: relative;
  width: 25px;
  height: 20px;
  animation: heartbeat 1s infinite;
  margin:0 auto;
  z-index: 9999;
}
#smallHeart3:before,
#smallHeart3:after {
  position: absolute;
  content: "";
  left: 12.5px;
  top: 0;
  width: 12.5px;
  height: 20px;
  background: red;
  -moz-border-radius: 12.5px 12.5px 0 0;
  border-radius: 12.5px 12.5px 0 0;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 100%;
  -moz-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  -o-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
#smallHeart3:after {
  left: 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  transform-origin :100% 100%;
}
/* End Smallest Heart 3 */

/* Start Smallest Heart 4 */
#smallHeart4 {
  position: relative;
  width: 25px;
  height: 20px;
  animation: heartbeat 1s infinite;
  margin:0 auto;
  z-index: 9999;
}
#smallHeart4:before,
#smallHeart4:after {
  position: absolute;
  content: "";
  left: 12.5px;
  top: 0;
  width: 12.5px;
  height: 20px;
  background: red;
  -moz-border-radius: 12.5px 12.5px 0 0;
  border-radius: 12.5px 12.5px 0 0;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 100%;
  -moz-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  -o-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
#smallHeart4:after {
  left: 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  transform-origin :100% 100%;
}
/* End Smallest Heart 4 */

</style>