<template>
  <div class="m-2 p-2">

    <div class="container">
      <div class="row">
        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 position-relative h-100">
          <Donation/>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Donation from "./Donation";

export default {
  name: 'Donate',
  components: {
    "Donation": Donation
  },
  data () {
    return {
    }
  }
}
</script>

<style>

</style>